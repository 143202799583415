export const REQUEST_DATA = {
  limit: 20,
  page: 1,
  query: ""
}

export const REQUEST_DATA_WITH_OFFSET = {
  limit: 20,
  offset: 0,
  page: 1,
  query: "",
}

export const REQUEST_LANDING_PAGE_IMAGE = {
  web_filter: {
    offset:0,
    limit:1000,
    keyword:"",
    order_by:[]
  }
}

export const REQUEST_ATTENDANCE_PAIR = {
  web_filter: {
    offset:0,
    limit:10,
    keyword:"",
    order_by:[]
  }
}

export const REQUEST_DETAILING_AID_LIST = {
  web_filter: {
    offset:0,
    limit:1000,
    keyword:"",
    order_by:[]
  }
}

export const REQUEST_PRODUCTS_DA = {
  web_filter: {
    offset:0,
    limit:1000,
    keyword:"",
    order_by:[]
  }
}

export const REQUEST_SPECIALIZATION_DA = {
  web_filter: {
    offset:0,
    limit:1000,
    keyword:"",
    order_by:[]
  }
}

export const REQUEST_MAP_SPECIALIZATION_DA = {
  web_filter: {
    offset:0,
    limit:1000,
    keyword:"",
    order_by:[
      {
        "column": "MDAS.priority",
        "is_asc": true
      },
    ]
  }
}

export const REQUEST_LIST_INSTITUTION = {
  web_filter: {
    offset:0,
    limit:1000,
    keyword:"",
    order_by:[
      {
        "column": "INS.status",
        "is_asc": true
      },
      {
        "column": "INS.institution_id",
        "is_asc": true
      }
    ]
  }
}

export const REQUEST_INSTITUTION_MAINTENANCE = {
  "web_filter": {
      "offset": 0,
      "limit": 10,
      "keyword": "",
      "getMore": false,
      "filters" : {
          "area_id": 0,
          "district_id": 0,
          "territory_id": 0,
          "status": 0,
          "getForApproval": false
        }
    }
}

export const REQUEST_MATERIAL_MONITORING = {
  "web_filter": {
      "offset": 0,
      "limit": 10,
      "keyword": "",
      "getMore": false,
      "filters" : {
        "area_id": 0,
        "district_id": 0,
        "territory_id": 0
      
        }
    }
}

export const REQUEST_DOCTOR_MAINTENANCE = 
  {
    "web_filter": {
        "offset": 0,
        "limit": 10,
        "keyword": "",
        "getMore": false,
        "filters" : {
            "doctor_id": 0,
            "area_id": 0,
            "territory_id": 0,
            "district_id": 0,
            "institution_id": 0,
            "class_id": 0,
            "specialization_id": 0,
            "cycle": 0,
            "status": 6,
            "approval_status": 0
        }
    }
}

export const POST_REQUEST_DOCTOR_MAINTENANCE =
{
  "doctor":{},
  "institution":{},
  "mapping":{},
  "products":[],
}

export const GET_SO_PRODUCTS = 
  {
    "web_filter": {
        "offset": 0,
        "limit": 10,
        "keyword": "",
        "getMore": false,
        "filters" : {
            "institution_id": 0,
            "cycle": 0,
            "status": 6
        },
        "order_by":[
          {
            "column": "description",
            "is_asc": true
          }
        ]
    }
}

export const REQUEST_USERS_MAINTENANCE = {
  "web_filter": {
      "offset": 0,
      "limit": 10,
      "keyword": "",
      "filters": {
        "status": 0,
        "area_id": 0,
        "district_id": 0,
        "territory_id": 0,
        "role": 0,
        "institution_id": 0,
      }
  }
}

export const GET_SALES_REPORT = 
  {
    "web_filter": {
      "filters" : {
        "product_id": 0,
        "channel": "",
        "display": 0,
        "user_id": 0,
        "file_id": 0
      }
    }
  }

export const GET_ITINERARY_REPORT = 
  {
    "web_filter": {
      "limit": 10,
      "offset": 0,
      "filters": {
        "area_id": 0,
        "district_id": 0,
        "dm": 0,
        "cycle": 0,
        "year": 0,
        "callType": 0,
      }
    }
  }

export const GET_TUTORIALS = 
  {
    "web_filter": {
      "limit": 10,
      "offset": 0,
      "keyword": '',
      "filters": {
        "group_id": 0,
        "user_type_id": 0,
        "status": 0,
      }
    }
  }

  export const GET_FEATURED_PRODUCT = 
  {
    "web_filter": {
      "limit": 10,
      "offset": 0,
      "keyword": '',
      "filters": {
        "start_date": 0,
        "end_date": 0,
        "status": 0,
        "category": 0,
        "selected_category": 0,
        "specialization": 0
      }
    }
  }

  export const GET_FEATURED_PRODUCT_SPECIALIZATION = 
  {
    "web_filter": {
      "limit": 10,
      "offset": 0,
      "filters": {
        "specialization_category": 0,
        "division_id": 0,
        "area_id": 0,
        "district_id": 0
      }
    }
  }
