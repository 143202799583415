import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  LANGUAGES,
  KEY_STORAGE_LANGUAGE,
  DEFAULT_LANGUAGE,
} from "../../utils/constant";
import { HttpService } from "../http/http.service";
import * as _ from "lodash";
import { TranslationWidth } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private acpSettings: any = null;
  private userSelectedLanguage: string | null = null;

  constructor(private translate: TranslateService, private http: HttpService) {}

  public setAcpSettings(settings: any) {
    this.acpSettings = settings;
  }

  public setUserSelectedLanguage(language: string) {
    this.userSelectedLanguage = language;
    localStorage.setItem("USER_SELECTED_LANGUAGE", language);
  }

  public initLocale() {
    let langs = [];
    Object.values(LANGUAGES).forEach((typeLanguages) => {
      langs = [...langs, ...typeLanguages.map((lang) => lang.value)];
    });

    this.translate.addLangs(langs);
    let defaultLang = DEFAULT_LANGUAGE;
    let defaultType = "SFE";

    if (this.acpSettings) {
      const langSetting = this.acpSettings.find(
        (setting) => setting.default_name === "DEFAULT_WEB_LANGUAGE"
      );
      const typeSetting = this.acpSettings.find(
        (setting) => setting.default_name === "PRODUCT_TYPE"
      );

      if (langSetting) {
        const productType = typeSetting?.default_value;
        defaultType = (productType === "SFE" || productType === "ISSA") ? productType : "SFE";
        defaultLang = `${defaultType}${langSetting.default_value}`;
      }
      if (typeSetting) {
        defaultType = (typeSetting.default_value === "SFE" || typeSetting.default_value === "ISSA") 
          ? typeSetting.default_value 
          : "SFE";
      }
    }
    this.translate.setDefaultLang(defaultLang);

    const userLanguage = localStorage.getItem("USER_SELECTED_LANGUAGE");
    let lang;

    if (
      userLanguage &&
      userLanguage !== "null" &&
      userLanguage !== "undefined"
    ) {
      lang = userLanguage;
    } else {
      lang = defaultLang;
    }
    localStorage.setItem(KEY_STORAGE_LANGUAGE, lang);
    this.translate.use(lang);
    return { defaultLang, defaultType };
  }

  public getLocale() {
    return localStorage.getItem(KEY_STORAGE_LANGUAGE);
  }

  public updateLocale(locale) {
    this.translate.use(locale);
    localStorage.setItem(KEY_STORAGE_LANGUAGE, locale);
  }

  public getTranslation(key, params) {
    var translation;
    this.translate.get(key, params).subscribe((res: string) => {
      translation = res;
    });
    return translation;
  }

  public getAcpSettings() {
    return this.acpSettings;
  }
}
