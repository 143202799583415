import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { S3 } from 'aws-sdk';
import { saveAs } from 'file-saver';
import { DATETIME_FORMAT_FILE_PREFIX_S3 } from '../../utils/constant';
import * as moment from 'moment';
import * as _ from 'lodash';
import { HelperService } from '../helper/helper.service';
import { environment } from '../../../environments/environment';
import { bindNodeCallback, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(
    private authService: AuthService,
    private helper: HelperService
  ) { }

  public download({ bucketName, key, filename }: {bucketName: string, key: string, filename?: string}, callback: (err?, data?: any) => void){
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        return callback(err);
      }

      let bucket = new S3();

      let params = {
        Bucket: bucketName,
        Key: key,
      };

      bucket.getObject(params, (err, data) => {
        if(err){
          return callback(err);
        }
        saveAs(new Blob([data.Body as any], { type: data.ContentType }), filename);
        callback(null, data);
      });
    });
  }

  public downloadV2({ folderName, key, filename }: {folderName: string, key: string, filename?: string}, callback: (err?, data?: any) => void)
  /* Uses environment variable instead of passed bucketName */
  {
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        return callback(err);
      }

      let bucket = new S3({ region: environment.region });
      let bucketName = folderName ? (environment.storage.AWSS3.bucket + '/' + folderName) : environment.storage.AWSS3.bucket;
      let params = {
        Bucket: bucketName,
        Key: key,
      };

      bucket.getObject(params, (err, data) => {
        if(err){
          return callback(err);
        }
        saveAs(new Blob([data.Body as any], { type: data.ContentType }), filename);
        callback(null, data);
      });
    });
  }

  public upload(
      { bucketName, file, options }: {bucketName: string, file: File, options?: S3.ManagedUpload.ManagedUploadOptions}, 
      callback: (err?, s3Key?: any) => void
    ){
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        return callback(err);
      }

      let bucket = new S3();

      let extraPrefix = this.helper.randomString(6);
      let kebabFilename = _.kebabCase(file.name);
      let now = moment().format(DATETIME_FORMAT_FILE_PREFIX_S3);

      let key = `${extraPrefix}-${now}-${kebabFilename}`;

      let params = {
        Bucket: bucketName,
        Key: key, 
        Body: file,
        ContentType: file.type
      };

      bucket.upload(params, options, (err) => {
        if(err){
          return callback(err);
        }
        callback(null, key);
      });
    });
  }

  public uploadV2(
      { folderName, file, options }: {folderName: string, file: File, options?: S3.ManagedUpload.ManagedUploadOptions}
    )
  {
    return new Observable((subsriber) => {
      this.authService.checkAWSCredentials( (err) => {
        if(err){
          subsriber.error(err);
          subsriber.complete()
        }
  
        let bucket = new S3({ region: environment.region });
  
        let now = moment().format(DATETIME_FORMAT_FILE_PREFIX_S3);
  
        let key = `${file.name}`;
        let bucketName = folderName ? (environment.storage.AWSS3.bucket + '/' + folderName) : environment.storage.AWSS3.bucket;

        console.log(bucketName)
  
        let params = {
          Bucket: bucketName,
          Key: key, 
          Body: file,
          ContentType: file.type,
          ACL: 'bucket-owner-full-control' 
        };  

        bucket.upload(params, options, (err) => {
          if(err){
            subsriber.error(err);
            subsriber.complete()
          }
          subsriber.next(key);
          subsriber.complete()
        });
      });
    })
  }

  public uploadBulkV2(
    { folderName, files, options }: { folderName: string, files: File[], options?: S3.ManagedUpload.ManagedUploadOptions }
  ) {
    return new Observable((subscriber) => {
      this.authService.checkAWSCredentials((err) => {
        if (err) {
          subscriber.error(err);
          subscriber.complete();
        }
  
        const bucket = new S3({ region: environment.region });
        const bucketName = folderName
          ? `${environment.storage.AWSS3.bucket}/${folderName}`
          : environment.storage.AWSS3.bucket;
  
        const uploadPromises = files.map((file) => {
          const key = `${file.name}`;; 
          const params = {
            Bucket: bucketName,
            Key: key,
            Body: file,
            ContentType: file.type,
            ACL: 'bucket-owner-full-control',
          };
  
          return bucket.upload(params, options).promise();
        });
  
        Promise.all(uploadPromises)
          .then((results) => {
            subscriber.next(results.map((res) => res.Key)); 
            subscriber.complete();
          })
          .catch((error) => {
            subscriber.error(error);
            subscriber.complete();
          });
      });
    });
  }

    public uploadTutorial(
      { bucketName, key, file, options }: {bucketName: string, key: string, file: File, options?: S3.ManagedUpload.ManagedUploadOptions}
    )
  {
    return new Observable((subsriber) => {
      this.authService.checkAWSCredentials( (err) => {
        if(err){
          subsriber.error(err);
          subsriber.complete()
        }

        let bucket = new S3({ region: environment.region });

        let params = {
          Bucket: environment.storage.AWSS3.bucket,
          Key: `${bucketName}/${key}`, 
          Body: file,
          ContentType: file.type,
          ACL: 'bucket-owner-full-control'
        };  
        bucket.upload(params, options, (err) => {
          if(err){
            subsriber.error(err);
            subsriber.complete()
          }
          subsriber.next(key);
          subsriber.complete()
        });
      });
    })
  }

  public viewImage({bucketName, key, filename }: {bucketName: string, key: string, filename?: string}, callback:(err?,data?: any) => any){
    this.authService.checkAWSCredentials( (err) => {
      if(err){
        return callback(err);
      }

      let bucket = new S3();

      let params = {
        Bucket: bucketName,
        Key: key,
      };

      bucket.getObject(params, (err, data) => {
        if(err){
          return callback(err);
        }
        //saveAs(new Blob([data.Body as any], { type: data.ContentType }), filename);
        callback(null, data);
      });
    });
  }


  public async getPresignedUrl(path: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        this.authService.checkAWSCredentials((err) => {
            if (err) {
                reject(err);
            } else {
              // temporarily hard code
                // const bucketName = "sfe-dev-data-maintenance";
                const bucketName = environment.bucketName;
                const s3 = new S3();
                const params = {
                    Bucket: bucketName,
                    Key: path,
                    Expires: 3600,
                    ResponseContentDisposition: 'attachment'
                };

                s3.getSignedUrl('getObject', params, (err, url) => {
                    if (err) {
                        console.error('Error getting URL:', err);
                        reject(err);
                    } else {
                        const preSignedUrl = url;
                        resolve(preSignedUrl);
                      }
                  });
              }
          });
      });
  }

  public downloadObjectObservable(bucketName, s3Key, filename): Observable<any> {
    bucketName = bucketName ? bucketName : environment.bucketName
    return this.authService.initAWSCredentialsObservable().pipe(
      switchMap(_ => this.getObjectObservable(bucketName, s3Key)),
      switchMap((data: any) => this.s3SaveAsFile(filename, data))
    );
  }

  public getObjectObservable(bucketname: string, s3Key: string): Observable<any> {
    const s3 = new S3({
      region: 'ap-southeast-1'
    });

    const params = {
      Bucket: bucketname,
      Key: s3Key,
    };

    const s3GetObjectFunction = bindNodeCallback((callback: any) => s3.getObject(params, callback));
    return s3GetObjectFunction();
  }

  public s3SaveAsFile(filename: string, s3Object: any): Observable<any> {
    const blobOption = {
      type: s3Object.ContentType
    };
    const blobParts: any[] = [
      s3Object.Body as Blob
    ];
    this.saveAsFile(filename, blobParts, blobOption);
    return of(s3Object);
  }

  public saveAsFile(filename: string, blobParts: any[], options?: BlobPropertyBag) {
    const blob = new Blob(blobParts, options);
    return saveAs(blob, filename);
  }

  // delete multiple objects - to be finalized
  public deleteObjectsV2(
    { folderName, keys }: { folderName: string, keys: string[] }
  ): Observable<string[]> {
    return new Observable((subscriber) => {
      this.authService.checkAWSCredentials((err) => {
        if (err) {
          subscriber.error(err);
          subscriber.complete();
          return;
        }

        let bucket = new S3({ region: environment.region });

        let bucketName = environment.storage.AWSS3.bucket; 

        let params: S3.Types.DeleteObjectsRequest = {
          Bucket: bucketName,
          Delete: {
            Objects: keys.map(key => ({ Key: `${folderName}/${key}` })), 
            Quiet: false 
          }
        };

        bucket.deleteObjects(params, (err, data) => {
          if (err) {
            subscriber.error(err);
            subscriber.complete();
            return;
          }

          const deletedKeys = data.Deleted.map(deleted => deleted.Key);
          const errors = data.Errors;

          if (errors && errors.length > 0) {
            console.error('Errors occurred while deleting objects:', errors);
            subscriber.error(errors);
            subscriber.complete();
            return;
          }

          console.log('Deleted objects:', deletedKeys);
          subscriber.next(deletedKeys);
          subscriber.complete();
        });
      });
    });
  }


  // delete single object from s3
  public deleteObject(key: string): Observable<string> {
    return new Observable((subscriber) => {
      this.authService.checkAWSCredentials((err) => {
        if (err) {
          subscriber.error(err);
          subscriber.complete();
          return;
        }
  
        let bucket = new S3({ region: environment.region });
        let bucketName = environment.storage.AWSS3.bucket; 
  
        const params: S3.Types.DeleteObjectRequest = {
          Bucket: bucketName,
          Key: key,
        };
  
        bucket.deleteObject(params, (err, data) => {
          if (err) {
            console.log(err)
            subscriber.error(err);
            subscriber.complete();
            return;
          }
  
          console.log('Deleted object:', key);
          subscriber.next(key); // Return the deleted file name
          subscriber.complete();
        });
      });
    });
  }

}