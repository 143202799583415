// custom-lightbox.adapter.ts
import { Injectable } from '@angular/core';
import { LightboxAdapter } from '@fnxone/ngx-photoswipe';

@Injectable()
export class CustomLightboxAdapter extends LightboxAdapter {


    constructor() {
        super();
        console.log('✅ CustomLightboxAdapter is being used');
    }
    // ✅ Set your options here
    allowPanToNext = true;
    mouseUsed = false;
    loop = true;
    pinchToClose = false; 
    closeOnScroll = false;
    closeOnVerticalDrag = false;
    showHideOpacity = true;
    escKey = false;
    arrowKeys = false;
    maxSpreadZoom = 1; // 👈 Prevents zooming in
    getDoubleTapZoom = () => 1; // 👈 Disables double-tap zoom
    zoomEl = false; // 👈 Disables zoom button
    fullscreenEl = false;

    getPageURLForShare = function (shareButtonData: any) {
        return window.location.href;
    };
}
