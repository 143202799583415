import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { DialogService, LanguageService } from 'src/app/services';
import { SignatureModalComponent } from '../signature-modal/signature-modal.component';
import axios from 'axios';
import { OfflineStorageService } from 'src/app/pages/quick-sign/offline-storage.service';
import { AcpIndexDBService } from 'src/app/services/indexed-db/acp-index-db.service';
import * as moment from 'moment';

@Component({
  selector: 'esc-image-capture-modal',
  templateUrl: './image-capture-modal.component.html',
  styleUrls: ['./image-capture-modal.component.scss']
})
export class ImageCaptureModalComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  @ViewChildren('photoCanvas') photoCanvas!: QueryList<ElementRef<HTMLCanvasElement>>;

  public field: any = {};
  public isPhotoCaptured = false;
  public isCameraStreaming = false;
  public videoDevices: MediaDeviceInfo[] = [];
  public selectedCamera: string | undefined;
  public isSaveButtonDisabled = false;
  public loading = {
    save: false
  };

  private location: { latitude: number; longitude: number; address: string } = {
    latitude: 0, longitude: 0, address: ''
  };

  private locationSubject = new BehaviorSubject<{ latitude: number; longitude: number; address: string }>
    ({ latitude: 0, longitude: 0, address: '' });
  location$ = this.locationSubject.asObservable();

  public userHolder = {
    id: 0,
    first_name: "",
    middle_name: "",
    last_name: "",
    username: "",
    email_address: "",
    code: "",
  };

  private readonly LAST_CAMERA_KEY = 'lastUsedCamera';

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private matDialogRef: MatDialogRef<ImageCaptureModalComponent>,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private indexDBService: AcpIndexDBService,
    private offlineStorage: OfflineStorageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.clearLastUsedCamera();
    this.enumerateVideoDevices();

    const userData = this.indexDBService.getFromLocalStorage("app_user");
    if (userData && Object.keys(userData).length > 0) {
      this.userHolder = userData;
    }

    this.field = this.data
    await this.getPosition();
  }

  ngAfterViewInit() {
    if (this.videoPlayer) {
      this.initCamera();
    }
  }

  async enumerateVideoDevices(): Promise<void> {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop());
        });
  
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoDevices = devices.filter(device => device.kind === 'videoinput');
  
      if (this.videoDevices.length > 0) {
        // Try to get the last used camera
        const lastUsedCamera = this.getLastUsedCamera();
        
        // Check if the last used camera is still available
        const cameraStillExists = lastUsedCamera && 
          this.videoDevices.some(device => device.deviceId === lastUsedCamera);
        
        // Use last camera if available, otherwise use first camera
        this.selectedCamera = cameraStillExists ? 
          lastUsedCamera : 
          this.videoDevices[0].deviceId;
          
        this.initCamera();
      } else {
        this.dialogService.openSnackBar(this.languageService.getTranslation("MESSAGE_INFO_NO_VIDEO_DEVICES_FOUND", {}));
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
      this.dialogService.openSnackBar(this.languageService.getTranslation("MESSAGE_INFO_NO_CAMERA_ACCESS", {}));
    }
  }
  
  ngOnDestroy(): void {
    this.stopCamera();

    console.log('destroyed')
    this.destroy$.next(); // Emit to complete the observable
    this.destroy$.complete(); // Optional, completes the subject
  }

  async getPosition() {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser');
      return;
    }

    if (navigator.onLine) {
      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          });
        });

        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        try {
          const address = await this.getAddress(latitude, longitude);
          const locationData = { latitude, longitude, address };
          this.location = locationData;
          this.locationSubject.next(locationData);
          console.log('Location updated:', this.location); // Debug log

          // Save location to IndexedDB
          const userLocation = { location: locationData };
          await this.offlineStorage.saveUserLocation(userLocation, this.userHolder.id);
        } catch (error) {
          console.error('Error getting address:', error);
        }
      } catch (error) {
        console.error('Error getting position:', error);
      }
    } else {
      const savedLocation = await this.offlineStorage.getUserLocation(this.userHolder.id);
      if (savedLocation.length > 0) {
        this.location = savedLocation[0].location;
        this.locationSubject.next(savedLocation[0].location);
        console.log('Retrieved saved location:', savedLocation[0].location); // Debug log
      } else {
        console.error('No saved location found.');
      }
    }
  }

  private async getAddress(latitude: number, longitude: number): Promise<string> {
    try {
      const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      const response = await axios.get(apiUrl, {

      });

      if (response.data && response.data.display_name) {
        console.log('Address received:', response.data.display_name); // Debug log
        return response.data.display_name;
      } else {
        throw new Error('No address data in response');
      }
    } catch (error) {
      console.error('Reverse geocoding failed:', error);
      throw error;
    }
  }

  async initCamera(): Promise<void> {
    if (this.selectedCamera && this.videoPlayer) {
      try {
        // Try with specific device first
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { exact: this.selectedCamera },
          }
        });
        this.handleStream(stream);
      } catch (error) {
        // If specific device fails, try with any camera
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
          this.handleStream(stream);
        } catch (secondError) {
          console.error('Error accessing any camera:', secondError);
          this.dialogService.openSnackBar(this.languageService.getTranslation("MESSAGE_INFO_NO_CAMERA_ACCESS", {}));
        }
      }
    }
  }
  
  private handleStream(stream: MediaStream): void {
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.srcObject = stream;
    this.isCameraStreaming = true;
    this.cdr.detectChanges();
  }

  stopCamera(): void {
    const videoElement = this.videoPlayer?.nativeElement;
    const stream = videoElement?.srcObject as MediaStream | null;

    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoElement.srcObject = null;
      this.isCameraStreaming = false;
    }
  }

  private executeReset(): void {
    this.stopCamera();
    this.isPhotoCaptured = false;
    this.initCamera();
    this.enumerateVideoDevices();
  }

  resetCamera(): void {
    this.dialogService.confirm({
      title: 'Clear Photo',
      message: 'Are you sure you want to clear the current photo?'
    }).subscribe(result => {
      if (result) {
        this.executeReset();
      }
    });
  }

  changeCamera2(): void {
    if (this.videoDevices.length >= 2) {
      if (this.isCameraStreaming) {
        this.stopCamera();
      }
      const currentDeviceIndex = this.videoDevices.findIndex(device => 
        device.deviceId === this.selectedCamera
      );
      let nextDeviceIndex = currentDeviceIndex + 1;
      if (nextDeviceIndex >= this.videoDevices.length) {
        nextDeviceIndex = 0;
      }
      const nextDeviceId = this.videoDevices[nextDeviceIndex].deviceId;
      
      navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: nextDeviceId },
        },
      })
        .then((stream) => {
          const videoElement = this.videoPlayer.nativeElement;
          videoElement.srcObject = stream;
          this.selectedCamera = nextDeviceId;
          this.saveLastUsedCamera(nextDeviceId); // Save the new camera selection
          this.isCameraStreaming = true;
          this.cdr.detectChanges();
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    } else {
      this.dialogService.openSnackBar(this.languageService.getTranslation("MESSAGE_INFO_ONLY_ONE_VIDEO_DEVICE", {}));
      console.error('Less than two video devices available.');
    }
  }

  // Save the camera selection
  private saveLastUsedCamera(deviceId: string): void {
    localStorage.setItem(this.LAST_CAMERA_KEY, deviceId);
  }

  // Get the last used camera
  private getLastUsedCamera(): string | null {
    return localStorage.getItem(this.LAST_CAMERA_KEY);
  }
  private clearLastUsedCamera(): void {
    localStorage.removeItem(this.LAST_CAMERA_KEY);
  }

  captureImage(): void {
    const videoElement = this.videoPlayer?.nativeElement;
    if (!videoElement) {
      console.error('Video element is not initialized.');
      return;
    }

    this.isPhotoCaptured = true;
    this.cdr.detectChanges(); 

    setTimeout(() => {
      const canvasElement = this.photoCanvas.first?.nativeElement;
      if (!canvasElement) {
        console.error('Canvas element is not initialized.');
        return;
      }

      const context = canvasElement.getContext('2d');
      if (!context) {
        console.error('Canvas context is not initialized.');
        return;
      }

      const parentWidth = Math.min(window.innerWidth * 0.8);
      const parentHeight = window.innerHeight * 0.6;
      canvasElement.width = parentWidth;
      canvasElement.height = parentHeight;
  
      const scale = Math.min(
        parentWidth / videoElement.videoWidth,
        parentHeight / videoElement.videoHeight
      );

      const scaledWidth = videoElement.videoWidth * scale;
      const scaledHeight = videoElement.videoHeight * scale;
      const x = (parentWidth - scaledWidth) / 2;
      const y = (parentHeight - scaledHeight) / 2;

      context.drawImage( videoElement, x, y, scaledWidth, scaledHeight );
      //this.addWatermarkToPhoto(context, canvasElement);
      this.stopCamera();
      this.isSaveButtonDisabled = false;
    }, 0);
  }

  private trimWhiteSpace(context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    const pixels = context.getImageData(0, 0, canvas.width, canvas.height);
    const data = pixels.data;
    let bounds = {
      top: null,
      left: null,
      right: null,
      bottom: null
    };
  
    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const index = (y * canvas.width + x) * 4;
        const alpha = data[index + 3]; 
  
        if (alpha !== 0) {
          bounds.top = bounds.top === null ? y : Math.min(y, bounds.top);
          bounds.bottom = Math.max(y, bounds.bottom || 0);
          bounds.left = bounds.left === null ? x : Math.min(x, bounds.left);
          bounds.right = Math.max(x, bounds.right || 0);
        }
      }
    }
  
    const trimmedCanvas = document.createElement('canvas');
    const trimmedContext = trimmedCanvas.getContext('2d');
  
    if (!trimmedContext || bounds.top === null) {
      return canvas; 
    }
  
    const trimmedWidth = bounds.right - bounds.left + 1;
    const trimmedHeight = bounds.bottom - bounds.top + 1;
  
    trimmedCanvas.width = trimmedWidth;
    trimmedCanvas.height = trimmedHeight;
  
    trimmedContext.drawImage(
      canvas,
      bounds.left, bounds.top, trimmedWidth, trimmedHeight,
      0, 0, trimmedWidth, trimmedHeight
    );
  
    return trimmedCanvas;
  }
  

  async saveCapturedImage(): Promise<void> {
    this.loading.save = true;

    const canvasElement = this.photoCanvas.first?.nativeElement;
    if (!canvasElement) {
      console.error('Canvas element is not initialized.');
      return;
    }

    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    if (!tempCtx) return;

    tempCanvas.width = canvasElement.width;
    tempCanvas.height = canvasElement.height;
    tempCtx.drawImage(canvasElement, 0, 0);
    const trimmedCanvas = this.trimWhiteSpace(tempCtx, tempCanvas);

    const watermarkConfig = {
      position: 'bottomLeft',
      offsetX: 0,
      font: '9px Arial',
      fillStyle: 'rgba(255, 255, 255, 0.8)', 
      padding: 10,
      lineHeight: 16
    };

    // if field is 14 (Image w/ GPS), add watermark
    if (this.field.itemTypeId == 14) {
      this.addWatermarkToPhoto(trimmedCanvas.getContext('2d'), trimmedCanvas, this.location, watermarkConfig);
    }

    try {
      const imageUrl = trimmedCanvas.toDataURL('image/png');
      // const response = await fetch(imageUrl);
      // const blob = await response.blob();
      const timestamp = moment().format('YYYYMMDDHHmmss');  // Using underscore
      const context = 'forms';

      const fileName = `${context}-image-${timestamp}.png`;
      // const file = new File([blob], fileName, { type: 'image/png' });

      this.isSaveButtonDisabled = true;

      if (this.isPhotoCaptured) {
        this.matDialogRef.close({ 
          imageData: imageUrl,
          fileName: fileName
        });
      }

      this.executeReset();
      this.enumerateVideoDevices();
    } catch (error) {
      console.error('Failed to save captured image:', error);
      this.isSaveButtonDisabled = false;
    } finally {
      this.loading.save = false;
    }
  }

  private addWatermarkToPhoto(
    context: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    location: any,
    config: {
        font: string;
        fillStyle: string;
        padding: number;
        lineHeight: number;
    }
  ): void {
    const timestamp = moment().format('YYYYMMDDHHmmss');  // Using underscore

    const lines = [
        `Location: ${location.address || 'Unknown'}`,
        `Coordinates: ${location.latitude}, ${location.longitude}`,
        `Timestamp: ${timestamp}`
    ];

    context.font = config.font;
    
    const maxLineWidth = Math.max(...lines.map(line => context.measureText(line).width));
    const textBlockHeight = (lines.length * config.lineHeight) + (config.padding * 2);
    
    context.fillStyle = 'rgba(0, 0, 0, 0.5)'; 
    context.fillRect(
        config.padding, 
        canvas.height - textBlockHeight - config.padding,
        maxLineWidth + (config.padding * 2),
        textBlockHeight
    );

    // Draw text
    context.fillStyle = config.fillStyle;
    lines.forEach((line, index) => {
        const y = canvas.height - ((lines.length - index) * config.lineHeight) - config.padding;
        context.fillText(line, config.padding * 2, y);
    });
  }

  dialogClose() {
    this.matDialogRef.close();
  }

}
