import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AcpIndexDBService } from '../../services/indexed-db/acp-index-db.service';
import { CryptoService } from '../../services/encryption/crypto.service';

interface QuickCoverageSetting {
  id?: number;
  settings: {
    default_name: string;
    default_value: string;
    current_count?: number;
  };
}

interface QuickSign {
  id?: number;
  quick_sign: {
    is_matched: number;
    // add other properties as needed
  };
}

interface QuickPhoto {
  id?: number;
  quick_photo: {
    is_matched: number;
    // add other properties as needed
  };
}

interface UserLocation {
  id?: number;
  location: {
    latitude: number;
    longitude: number;
    address: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class OfflineStorageService {

  public settings: any[] = [];

  // Store names
  private readonly STORES = {
    SETTINGS: 'quick_coverage_settings',
    QUICK_SIGNS: 'quick_sign',
    QUICK_SIGN_IMAGES: 'quick_sign_images',
    QUICK_PHOTOS: 'quick_photo',
    QUICK_PHOTO_IMAGES: 'quick_photo_images',
    PENDING_UPLOADS: 'quick_coverage_pending_upload',
    PENDING_UPLOAD_IMAGES: 'quick_coverage_pending_upload_images',
    MATCH_LOG: 'quick_coverage_match_log',
    USER_LOCATION: 'quick_coverage_location'
  };

  constructor(
    private dbService: NgxIndexedDBService, 
    private indexDBService: AcpIndexDBService,
    private cryptoService: CryptoService
  ) {}

  // settings
  async saveSettings(settings: QuickCoverageSetting[], user_id: number): Promise<void> {
    await this.indexDBService.processAESToIDB('quick_coverage_settings', settings, user_id, 'settings');
  }

  async getSettings(user_id: number): Promise<QuickCoverageSetting[]> {
    return this.indexDBService.getDecryptedAesEntries('quick_coverage_settings', user_id, 'settings');
  }

  // quick sign list and details
  async saveQuickSigns(signs: QuickSign[], user_id: number): Promise<void> {
    await this.indexDBService.processAESToIDB(this.STORES.QUICK_SIGNS, signs, user_id, 'quick_sign');
  }

  async getQuickSigns(user_id: number): Promise<QuickSign[]> {
    return this.indexDBService.getDecryptedAesEntries(this.STORES.QUICK_SIGNS, user_id, 'quick_sign');
  }

  // quick photo list and details
  async saveQuickPhotos(photos: QuickPhoto[], user_id: number): Promise<void> {
    await this.indexDBService.processAESToIDB(this.STORES.QUICK_PHOTOS, photos, user_id, 'quick_photo');
  }

  async getQuickPhotos(user_id: number): Promise<QuickPhoto[]> {
    return this.indexDBService.getDecryptedAesEntries(this.STORES.QUICK_PHOTOS, user_id, 'quick_photo');
  }
  
  // quick sign images
  async saveQuickSignImages(files: File[], user_id: number): Promise<void> {
    await this.saveImages(this.STORES.QUICK_SIGN_IMAGES, files, user_id);
  }

  async getQuickSignImages(user_id: number): Promise<any[]> {
    return this.getImages(this.STORES.QUICK_SIGN_IMAGES, user_id);
  }

  // quick photo images
  async saveQuickPhotoImages(files: File[], user_id: number): Promise<void> {
    await this.saveImages(this.STORES.QUICK_PHOTO_IMAGES, files, user_id);
  }

  async getQuickPhotoImages(user_id: number): Promise<any[]> {
    return this.getImages(this.STORES.QUICK_PHOTO_IMAGES, user_id);
  }

  // pending upload images
  async savePendingUploadImage(file: File, user_id: number): Promise<void> {
    await this.saveImages(this.STORES.PENDING_UPLOAD_IMAGES, [file], user_id);
  }

  async getPendingUploadImages(user_id: number): Promise<any[]> {
    return this.getImages(this.STORES.PENDING_UPLOAD_IMAGES, user_id);
  }

  // pending upload list
  async savePendingUpload(upload: any, user_id: number): Promise<void> {
    let pendingUploads = await this.getPendingUploads(user_id);
    if (!pendingUploads) {
      pendingUploads = [];
    }
    pendingUploads.push(upload);
    await this.setPendingUploads(pendingUploads, user_id);
  }

  async getPendingUploads(user_id: number): Promise<any[]> {
    return this.indexDBService.getDecryptedAesEntries(this.STORES.PENDING_UPLOADS, user_id, 'pending_upload');
  }

  async setPendingUploads(pendingUploads: any[], user_id: number): Promise<void> {
    await this.indexDBService.processAESToIDB(this.STORES.PENDING_UPLOADS, pendingUploads, user_id, 'pending_upload');
  }

  // update quick signs
  async updateQuickSigns(fileDetails: any, user_id: number): Promise<void> {
    let quickSigns = await this.getQuickSigns(user_id);
    if (!quickSigns) {
      quickSigns = [];
    }
    quickSigns.push(fileDetails);
    await this.setQuickSigns(quickSigns, user_id);
  }

  async setQuickSigns(quickSigns: any[], user_id: number): Promise<void> {
    await this.indexDBService.processAESToIDB(this.STORES.QUICK_SIGNS, quickSigns, user_id, 'quick_sign');
  }

  // update quick photos
  async updateQuickPhotos(fileDetails: any, user_id: number): Promise<void> {
    let quickPhotos = await this.getQuickPhotos(user_id);
    if (!quickPhotos) {
      quickPhotos = [];
    }
    quickPhotos.push(fileDetails);
    await this.setQuickPhotos(quickPhotos, user_id);
  }

  async setQuickPhotos(quickPhotos: any[], user_id: number): Promise<void> {
    await this.indexDBService.processAESToIDB(this.STORES.QUICK_PHOTOS, quickPhotos, user_id, 'quick_photo');
  }

  // // update quick sign / quick photo images
  // async updateQuickSignImages(file: File, user_id: number): Promise<void> {
  //   try {
  //     let quickSignImages = await this.getQuickSignImages(user_id);
  //     console.log('Existing quick sign images:', quickSignImages);

  //     if (!quickSignImages) {
  //       quickSignImages = [];
  //     }
  //     quickSignImages.push(file);
  //     console.log('Updated quick sign images:', quickSignImages);

  //     await this.setQuickSignImages(quickSignImages, user_id);
  //     console.log('Quick sign images saved successfully');
  //   } catch (error) {
  //     console.error('Error updating quick sign images:', error);
  //   }
  // }

  // async updateQuickPhotoImages(file: File, user_id: number): Promise<void> {
  //   try {
  //     let quickPhotoImages = await this.getQuickPhotoImages(user_id);
  //     console.log('Existing quick photo images:', quickPhotoImages);

  //     if (!quickPhotoImages) {
  //       quickPhotoImages = [];
  //     }
  //     quickPhotoImages.push(file);
  //     console.log('Updated quick photo images:', quickPhotoImages);

  //     await this.setQuickPhotoImages(quickPhotoImages, user_id);
  //     console.log('Quick photo images saved successfully');
  //   } catch (error) {
  //     console.error('Error updating quick photo images:', error);
  //   }
  // }

  // remove pending upload
  async removePendingUpload(id: number): Promise<void> {
    await this.dbService.delete(this.STORES.PENDING_UPLOADS, id).toPromise();
    await this.dbService.delete(this.STORES.PENDING_UPLOAD_IMAGES, id).toPromise();
  }

  // user location
  async saveUserLocation(location: UserLocation, user_id: number): Promise<void> {
    await this.indexDBService.processAESToIDB('quick_coverage_location', [location], user_id, 'location');
  }

  async getUserLocation(user_id: number): Promise<UserLocation[]> {
    return this.indexDBService.getDecryptedAesEntries('quick_coverage_location', user_id, 'location');
  }

  public async deleteUserEntry(tableName: string, user_id: number): Promise<void> {
    try {
      const entries: any[] = await this.dbService.getAll(tableName).toPromise();
      const userEntries = entries.filter(entry => entry.id === user_id);
      
      if (userEntries.length > 0) {
        await Promise.all(userEntries.map(entry => this.dbService.delete(tableName, entry.id).toPromise()));
        // console.log(`Deleted entries for user ID: ${user_id} from table: ${tableName}`);
      } else {
        // console.log(`No entries found for user ID: ${user_id} in table: ${tableName}`);
      }
    } catch (error) {
      //console.error(`Error deleting entries for user ID: ${user_id} from table: ${tableName}`, error);
    }
  }

  async clearStoreById(storeName: string, user_id: number): Promise<void> {
    try {
      await this.deleteUserEntry(storeName, user_id);
      //console.log(`Cleared store: ${storeName} for user ID: ${user_id}`);
    } catch (error) {
      //console.error(`Error clearing store: ${storeName} for user ID: ${user_id}`, error);
    }
  }


  // Save images
  // async saveImages(storeName: string, files: File[], user_id: number): Promise<void> {
  //   try {
  //     let images = await this.getImages(storeName, user_id);
  //     console.log(`Existing images in ${storeName}:`, images);
  
  //     if (!images) {
  //       images = [];
  //     }
  //     images.push(...files.map(file => ({ file, filename: file.name })));
  //     console.log(`Updated images in ${storeName}:`, images);
  
  //     await this.setImages(storeName, images, user_id);
  //     console.log(`Images saved successfully in ${storeName}`);
  //   } catch (error) {
  //     console.error(`Error saving images in ${storeName}:`, error);
  //   }
  // }

  async saveImages(storeName: string, files: File[], user_id: number): Promise<void> {
    try {
      let images = await this.getImages(storeName, user_id);
      // console.log(`Existing images in ${storeName}:`, images);
  
      if (!images) {
        images = [];
      }
  
      const newImages = await Promise.all(files.map(async file => ({
        file: {
          name: file.name,
          type: file.type,
          size: file.size,
          lastModified: file.lastModified,
          content: await this.convertFileToBase64(file)
        },
        filename: file.name
      })));

      // Ensure existing images are correctly serialized
      const serializedExistingImages = await Promise.all(images.map(async image => {
        if (image.file instanceof File) {
          return {
            file: {
              name: image.file.name,
              type: image.file.type,
              size: image.file.size,
              lastModified: image.file.lastModified,
              content: await this.convertFileToBase64(image.file)
            },
            filename: image.filename
          };
        }
        return image;
      }));
  
      images = [...serializedExistingImages, ...newImages];
      // console.log(`Updated images in ${storeName}:`, images);  
  
      await this.setImages(storeName, images, user_id);
      // console.log(`Images saved successfully in ${storeName}`);
    } catch (error) {
      // console.error(`Error saving images in ${storeName}:`, error);
    }
  }
  
  private async convertFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
  // Get images
  // async getImages(storeName: string, user_id: number): Promise<any[]> {
  //   try {
  //     const entries: any[] = await this.dbService.getAll(storeName).toPromise();
  //     const existingEntry = entries.find(entry => entry.id === user_id);
  
  //     if (!existingEntry || !existingEntry['file']) {
  //       return [];
  //     }
  
  //     const decryptedData = [];
  //     for (const item of existingEntry['file']) {
  //       const itemToReturn = {};
  //       for (const [key, value] of Object.entries(item)) {
  //         if (key === 'file') {
  //           const decryptedFile = await this.cryptoService.decryptDataBased(String(value)); // Decrypt the file
  //           const blob = new Blob([decryptedFile], { type: 'image/png' }); // Ensure the correct MIME type
  //           const filename = item['filename']; // Use the stored filename or a default one
  //           const file = new File([blob], filename, { type: 'image/png' }); // Use the stored filename
  //           itemToReturn['file'] = file;
  //         } else if (key === 'filename') {
  //           itemToReturn['filename'] = value;
  //         }
  //       }
  //       decryptedData.push(itemToReturn);
  //     }
  
  //     console.log(`Retrieved images from ${storeName}:`, decryptedData);
  //     return decryptedData;
  //   } catch (error) {
  //     console.error(`Error retrieving images from ${storeName}:`, error);
  //     return [];
  //   }
  // }

  async getImages(storeName: string, user_id: number): Promise<any[]> {
    try {
      const entries: any[] = await this.dbService.getAll(storeName).toPromise();
      const existingEntry = entries.find(entry => entry.id === user_id);
  
      if (!existingEntry || !existingEntry['file']) {
        return [];
      }
  
      const decryptedData = [];
      for (const item of existingEntry['file']) {
        const itemToReturn: any = {};
        for (const [key, value] of Object.entries(item)) {
          if (key === 'file') {
            const decryptedFile = await this.cryptoService.decryptDataBased(String(value)); // Decrypt the file
            const fileData = JSON.parse(decryptedFile);
            const file = this.convertBase64ToFile(fileData.content, fileData.name, fileData.type, fileData.lastModified);
            itemToReturn['file'] = file;
          } else if (key === 'filename') {
            itemToReturn['filename'] = value;
          }
        }
        decryptedData.push(itemToReturn);
      }
  
      // console.log(`Retrieved images from ${storeName}:`, decryptedData);
      return decryptedData;
    } catch (error) {
      // console.error(`Error retrieving images from ${storeName}:`, error);
      return [];
    }
  }
  
  private convertBase64ToFile(base64: string, filename: string, type: string, lastModified: number): File {
    if (!base64) {
      throw new Error('Base64 content is undefined');
    }
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime, lastModified });
  }
  // Set images
  // async setImages(storeName: string, images: any[], user_id: number): Promise<void> {
  //   try {
  //     const encryptedImages = [];
  //     for (const image of images) {
  //       const encryptedImage = {
  //         file: await this.cryptoService.encryptDataBased(image.file), // Encrypt the file
  //         filename: image.filename // Include the filename
  //       };
  //       encryptedImages.push(encryptedImage);
  //     }

  //     const dataPass = {
  //       id: user_id,
  //       file: encryptedImages,
  //     };

  //     // Delete existing entry if it exists
  //     const existingEntries: any[] = await this.dbService.getAll(storeName).toPromise();
  //     const existingEntry = existingEntries.find(entry => entry.id === user_id);
  //     if (existingEntry) {
  //       await this.dbService.delete(storeName, user_id).toPromise();
  //     }

  //     // Add new entry
  //     await this.dbService.add(storeName, dataPass).toPromise();
  //     console.log(`Images set successfully in ${storeName}`);
  //   } catch (error) {
  //     console.error(`Error setting images in ${storeName}:`, error);
  //   }
  // }

  async setImages(storeName: string, images: any[], user_id: number): Promise<void> {
    try {
      // console.log(`Starting setImages for store: ${storeName}, user_id: ${user_id}`);
      const encryptedImages = [];
      for (const image of images) {
        // console.log(`Processing image: ${image.filename}`);
        const fileData = JSON.stringify(image.file);
        // console.log(`File data to encrypt: ${fileData}`);
        const encryptedFileData = await this.cryptoService.encryptDataBased(fileData);
        // console.log(`Encrypted file data: ${encryptedFileData}`);
        const encryptedImage = {
          file: encryptedFileData, // Encrypt the file
          filename: image.filename // Include the filename
        };
        encryptedImages.push(encryptedImage);
      }
  
      const dataPass = {
        id: user_id,
        file: encryptedImages,
      };
  
      // console.log(`Data to store: ${JSON.stringify(dataPass)}`);
  
      // Delete existing entry if it exists
      const existingEntries: any[] = await this.dbService.getAll(storeName).toPromise();
      const existingEntry = existingEntries.find(entry => entry.id === user_id);
      if (existingEntry) {
        // console.log(`Existing entry found for user_id: ${user_id}, deleting it`);
        await this.dbService.delete(storeName, user_id).toPromise();
      }
  
      // Add new entry
      await this.dbService.add(storeName, dataPass).toPromise();
      // console.log(`Images set successfully in ${storeName}`);
    } catch (error) {
      // console.error(`Error setting images in ${storeName}:`, error);
    }
  }

  async saveMatchData(matchData: any, user_id: number): Promise<void> {
    // console.log('Saving match data to IndexedDB:', matchData);
    await this.indexDBService.processAESToIDB('quick_coverage_match_log', matchData, user_id, 'matches');
    // console.log('Match data saved to IndexedDB');
  }

  async getMatchData(user_id: number): Promise<any[]> {
    // console.log('Retrieving match data from IndexedDB for user ID:', user_id);
    const matchData = await this.indexDBService.getDecryptedAesEntries('quick_coverage_match_log', user_id, 'matches');
    // console.log('Retrieved match data from IndexedDB:', matchData);
    return matchData;
  }

  async removeMatchData(id: number, user_id: number): Promise<void> {
    // console.log(`Removing match data from IndexedDB for ID: ${id} and user ID: ${user_id}`);
    const entries = await this.getMatchData(user_id);
    const updatedEntries = entries.filter(entry => entry.id !== id);
    await this.indexDBService.processAESToIDB('quick_coverage_match_log', updatedEntries, user_id, 'matches');
    // console.log(`Match data removed from IndexedDB for ID: ${id}`);
  }

  async updateIsMatched(tableName: string, id: number, user_id: number): Promise<void> {
    // console.log(`Updating is_matched value in ${tableName} for ID: ${id} and user ID: ${user_id}`);
    const entries = await this.indexDBService.getDecryptedAesEntries(tableName, user_id, tableName);
    // console.log(`Entries in ${tableName} before update:`, entries);
    const entry = entries.find(e => e.id === id || (Number(e.id) === id));
    if (entry) {
      entry.is_matched = 1;
      await this.indexDBService.processAESToIDB(tableName, entries, user_id, tableName);
      // console.log(`Updated is_matched value in ${tableName} for ID: ${id}`);
      // console.log(`Entries in ${tableName} after update:`, entries);
    } else {
      console.log(`Entry not found in ${tableName} for ID: ${id}`);
    }
  }

  async updatePendingUpload(id: number, user_id: number, filename?: string): Promise<void> {
    // console.log(`Updating is_matched value in pending_upload for ID: ${id} and user ID: ${user_id}`);
    const entries = await this.indexDBService.getDecryptedAesEntries('quick_coverage_pending_upload', user_id, 'pending_upload');
    const entry = entries.find(e => (e.id === id || (Number(e.id) === id)) || (e.fileName === filename) );
    if (entry) {
      if (entry.fileDetails) {
        entry.fileDetails.is_matched = 1;
      } else {
        entry.is_matched = 1;
      }
      await this.indexDBService.processAESToIDB('quick_coverage_pending_upload', entries, user_id, 'pending_upload');
      // console.log(`Updated is_matched value in pending_upload for ID: ${id}`);
    } else {
      console.log(`Entry not found in pending_upload for ID: ${id}`);
    }
  }
}