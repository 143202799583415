import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable, Subject, from, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CognitoUserPool, CognitoUserSession, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CognitoIdentityCredentials, config } from 'aws-sdk';
import { User } from '../../model';
import Auth from '@aws-amplify/auth'
import { resolve } from 'url';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import * as AWS from 'aws-sdk';
import { AuthenticationDetails } from 'amazon-cognito-identity-js';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _whenLoggedIn = new BehaviorSubject<User>(null);
  private _userPool: CognitoUserPool;
  private _user: User;

  get whenLoggedIn(){ return this._whenLoggedIn };
  get user(){ return this._user }
  get userPool(){ return this._userPool }

  
  constructor(  ) {
    this.initUserPool();
    this.initAWSCredentials();
  }

  private initUserPool(): void {
    if(localStorage.getItem('cognitoIdentityEndpoint') || ''){
      environment.cognitoIdentityEndpoint = localStorage.getItem('cognitoIdentityEndpoint')
      environment.auth.userPoolId = localStorage.getItem('cognitoUserPoolId')
      environment.auth.userPoolWebClientId = localStorage.getItem('cognitoAppClientId')
    }
    this._userPool = new CognitoUserPool({
      UserPoolId: environment.auth.userPoolId,
      ClientId: environment.auth.userPoolWebClientId,
    });
  }

  updateUserPool(userPoolId: string, clientId: string): void {
    environment.auth.userPoolId = userPoolId;
    environment.auth.userPoolWebClientId = clientId;
    this.initUserPool();
  }

  changePassword(cognito: CognitoUser, oldPassword: string, password: string) {

    return new Promise((resolve, reject) => {
      cognito.changePassword(oldPassword, password, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  }
  signUp(username: string, password: string, userAttributes: CognitoUserAttribute[], validationData: CognitoUserAttribute[], ) {

    return new Promise((resolve, reject) =>
      this.userPool.signUp(
      username,
      password,
      userAttributes,
      validationData,
      (err, result) => {
        if (err) {
          if (err.name === 'UsernameExistsException') {
            resolve(null);
            return;
          }
          else {
            reject(err);
          }
          return;
        }
        resolve(result);
      },
    ));
  }

  private setUser(user: User){
    this._user = user
    this._whenLoggedIn.next(user);
  }


  public initAWSCredentials(){
    let cognitoUser = this.userPool.getCurrentUser();
    // let cognitoUser1 = this.userPool.getCurrentUser().getUsername();
    this.getUser()
    if(!cognitoUser){
      return;
    }
 
    this.setUser(new User(cognitoUser));
 
    cognitoUser.getSession(async (err, session: CognitoUserSession) => {
      if(err){
        return console.log(err);
      }
      let logins = {
        [environment.cognitoIdentityEndpoint]: session.getIdToken().getJwtToken()
      };
      let creds = new CognitoIdentityCredentials({
        IdentityPoolId: environment.auth.identityPoolId,
        Logins: logins
      },{
        region: environment.region
      });
  
      config.credentials = creds;
      await ((config.credentials) as CognitoIdentityCredentials).refreshPromise();
    });
  }

  public checkAWSCredentials(callback: (err?) => void){
    this.initUserPool()
    let cognitoUser = this.userPool.getCurrentUser();

    if(!cognitoUser){
      return callback(true);
    }

    if(((config.credentials) as CognitoIdentityCredentials).needsRefresh()){
  
      cognitoUser.getSession((err, session: CognitoUserSession) => {
        if(err){
          console.log(err);
          return callback(err);
        }

        cognitoUser.refreshSession(session.getRefreshToken(), (err, session: CognitoUserSession) => {

          
          if(err){
            console.log(err);
            return callback(err);
          }
          
          let logins = {
            [environment.cognitoIdentityEndpoint]: session.getIdToken().getJwtToken()
          };
          
          let creds = new CognitoIdentityCredentials({
            IdentityPoolId: environment.auth.identityPoolId,
            Logins: logins
          },{
            region: environment.region
          });
      
          config.credentials = creds;
    
          ((config.credentials) as CognitoIdentityCredentials).refresh((err) => {
            if(err){
              console.log(err);
              return callback(err);
            }
            callback();
          });
        });
        
      });
    } else {
      callback();
    }
  }

  public logout(){
    let cognitoUser = this.userPool.getCurrentUser();
    if(cognitoUser){
      cognitoUser.signOut();
    }
    if (!config.credentials) {
      ((config.credentials) as CognitoIdentityCredentials).clearCachedId();
    }
    else{
      config.credentials = null;
    }
    
    let creds = new CognitoIdentityCredentials({
      IdentityPoolId: environment.auth.identityPoolId
    }, {
      region: environment.region
    });

    config.credentials = creds;
    this.setUser(null);
    localStorage.clear();
  }

  isCognitoConfigAvailable(): boolean {
    if (environment.auth.userPoolId == "CognitoUserPoolID" && environment.auth.userPoolWebClientId == "CognitoAppClientID"){
      environment.auth.userPoolId = null;
      environment.auth.userPoolWebClientId = null;
    }
    return !!environment.auth.userPoolId && !!environment.auth.userPoolWebClientId;
  }

  //New Code Try
  getCurrentSession() {
    return from(Auth.currentSession());
  }

  getCredentials() {
    return from(Auth.currentCredentials())
  }

  getEssentialCredentials(){
    return this.getCredentials().pipe(
      map((creds)=> Auth.essentialCredentials(creds))
    )
  }

  getUser(){
    let cognitoUser = this.userPool.getCurrentUser();
    if(cognitoUser){
      let cognitoUser = this.userPool.getCurrentUser().getUsername();
      localStorage.setItem("user", cognitoUser);
    }
  }
  
  public initAWSCredentialsObservable(): Observable<void> {
    return new Observable<void>((observer) => {
      let cognitoUser = this.userPool.getCurrentUser();
      this.getUser();
      if (!cognitoUser) {
        observer.complete();
        return;
      }

      this.setUser(new User(cognitoUser));

      cognitoUser.getSession(async (err, session: CognitoUserSession) => {
        if (err) {
          observer.error(err);
          return;
        }
        let logins = {
          [environment.cognitoIdentityEndpoint]: session.getIdToken().getJwtToken()
        };
        let creds = new CognitoIdentityCredentials({
          IdentityPoolId: environment.auth.identityPoolId,
          Logins: logins
        }, {
          region: environment.region
        });

        config.credentials = creds;
        try {
          await ((config.credentials) as CognitoIdentityCredentials).refreshPromise();
          observer.next();
          observer.complete();
        } catch (refreshError) {
          observer.error(refreshError);
        }
      });
    });
  } 
  
  async resendVerificationCode(username: string) {
    return await Auth.resendSignUp(username);
  }

  async confirmVerificationCode(verificationCode: string, email: string) {
    return await Auth.confirmSignUp(email, verificationCode);
  }

  getIdToken(): Observable<string> {
    return new Observable<string>((observer) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (!cognitoUser) {
        observer.error('No current user');
        return;
      }

      cognitoUser.getSession((err, session: CognitoUserSession) => {
        if (err) {
          observer.error(err);
          return;
        }
        const idToken = session.getIdToken().getJwtToken();
        observer.next(idToken);
        observer.complete();
      });
    });
  }

  reAuthenticateUser(password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const user = this.userPool.getCurrentUser();
      if (!user) {
        reject('No logged-in user found.');
        return;
      }

      user.getSession((err: any, session: any) => {
        if (err || !session.isValid()) {
          reject('Session is invalid. Please log in again.');
          return;
        }

        const username = session.getIdToken().payload['cognito:username'];

        const authDetails = new AuthenticationDetails({
          Username: username,
          Password: password
        });

        const cognitoUser = new CognitoUser({
          Username: username,
          Pool: this.userPool
        });

        cognitoUser.authenticateUser(authDetails, {
          onSuccess: () => resolve(),
          onFailure: (error) => reject(error.message) 
        });
      });
    });
  }
  
}
