export const LANGUAGE_TYPES = [
  {
    value: 'SFE',
    name: 'SFE'
  },
  {
    value: 'ISSA',
    name: 'ISSA'
  }
];

export const LANGUAGES = {
  SFE: [
    { 
      value: 'SFEen',
      name: 'English'
    },
    { 
      value: 'SFEfil',
      name: 'Filipino'
    },
    { 
      value: 'SFEid',
      name: 'Indonesia'
    },
    { 
      value: 'SFEen544',
      name: 'en544'
    },
    { 
      value: 'SFEen726',
      name: 'en726'
    }
  ],
  ISSA: [
    { 
      value: 'ISSAen',
      name: 'English'
    },
    { 
      value: 'ISSAfil',
      name: 'Filipino'
    },
    { 
      value: 'ISSAid',
      name: 'Indonesia'
    },
    { 
      value: 'SFEen544',
      name: 'en544'
    },
    { 
      value: 'SFEen726',
      name: 'en726'
    }
  ]
};

export const DEFAULT_LANGUAGE = 'SFEen';
export const KEY_STORAGE_LANGUAGE = 'language';