import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
@Component({
  selector: 'esc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version = environment.ngswVersion

  constructor() { }

  ngOnInit() {
  }

}
