import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { PageNotFoundComponent } from './auth/page-not-found/page-not-found.component';
import { AuthGuard } from './auth/guard/auth.guard';
import { GuestGuard } from './auth/guard/guest.guard';
import { ForgotPasswordGetEmailComponent } from './auth/forgot-password-get-email/forgot-password-get-email.component';
import { VerifyCodeComponent } from './auth/forgot-password-get-email/verify-code/verify-code.component';
import { VerifyGuard } from './auth/guard/verify.guard';





export const routes: Routes = [
	{ 
		path: '',
		loadChildren: () => import('../app/pages/pages.module').then(m => m.PagesModule),
		canActivate: [ AuthGuard ]
	},
	{ 
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
	},
	{
		path: 'landing-page',
		data: {
			name: 'landingpage',
		  },
		loadChildren: () => import('../app/pages/landing-page/landing-page.module').then(m => m.LandingPageModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: 'terms-and-privacy', 
		loadChildren: () => import('../app/pages/terms-and-privacy/terms-and-privacy.module').then(m => m.TermsAndPrivacyModule)
	},
	{ 
		path: '**', 
		component: PageNotFoundComponent
	}
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules  // 👈 this line does the magic
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
