<h1 mat-dialog-title class="modal-header">{{ field.caption }}</h1>

<div id="image-capture-modal" class="body" fxLayout="column" fxLayoutGap="20px" fxFlexFill>

	<mat-card class="custom-card">
		<mat-progress-bar mode="indeterminate" *ngIf="loading.save" fxLayoutGap="16px"></mat-progress-bar>
	
		<mat-card-content class="card-content">
			<video #videoPlayer *ngIf="!isPhotoCaptured" width="100%" height="20%" autoplay muted playsinline class="video-player"></video>
			<canvas #photoCanvas *ngIf="isPhotoCaptured" class="photo-canvas"></canvas>
		</mat-card-content>
	
		<div class="card-footer">
			<div class="action-btns">
				<button mat-icon-button *ngIf="isPhotoCaptured" (click)="resetCamera()" style="color: red" class="ms-2" matTooltip="Delete">
					<mat-icon>delete</mat-icon>
				</button>                    
				<button mat-raised-button *ngIf="!isPhotoCaptured" (click)="changeCamera2(selectedCamera)" class="ms-2" matTooltip="Switch Camera">
					<i class="material-icons">cameraswitch</i>
				</button>          
				<button mat-raised-button *ngIf="!isPhotoCaptured" (click)="captureImage()" class="ms-2" matTooltip="Capture">
					<mat-icon>camera</mat-icon>
				</button>
			</div>
		</div>
	</mat-card>

	<div class="buttons" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
		<button id="signature-modal-btn-submit" mat-button style='margin-right:5px' (click)="saveCapturedImage()" class="ms-2" color="primary" [disabled]="isSaveButtonDisabled || loading.save || !isPhotoCaptured">{{ 'SAVE' | translate }}</button>
		<button id="signature-modal-btn-cancel" mat-button (click)="dialogClose()">{{ 'CANCEL' | translate }}</button>  
	</div>

</div>