import { DBConfig } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
    name: 'revampDB',
    version: 109,
    objectStoresMeta: [{
        store: 'acp_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } },
        ]
    },
    {
        store: 'app_sync_trigger',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'sync_trigger', keypath: 'sync_trigger', options: { unique: false } },
        ]
    },
    {
        store: 'acp_call_modes',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'call_modes', keypath: 'call_modes', options: { unique: false } },
        ]
    },
    {
        store: 'acp_cycle_dates',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_dates', keypath: 'cycle_dates', options: { unique: false } },
        ]
    },
    {
        store: 'acp_holder',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'calls', keypath: 'calls', options: { unique: false } },
        ]
    },
    {
        store: 'acp_postcall_types',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'types', keypath: 'types', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activities_holder',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activity_calls', keypath: 'activity_calls', options: { unique: false } },
        ]
    },
    {
        store: 'acp_doctor_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctors', keypath: 'doctors', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activities_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activities', keypath: 'activities', options: { unique: false } },
        ]
    },
    {
        store: 'app_user',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'user', keypath: 'user', options: { unique: false } },
        ]
    },
    {
        store: 'web_permissions',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'permissions', keypath: 'permissions', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_incidental_list', keypath: 'acp_incidental_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_incidental_activity_list', keypath: 'acp_incidental_activity_list', options: { unique: false } },
        ]

    },

    
    {
        store: 'acp_forms_planned', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_forms_planned_list', keypath: 'acp_forms_planned_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_forms_incidental', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_forms_incidental_list', keypath: 'acp_forms_incidental_list', options: { unique: false } },
        ]
    },

    {
        store: 'acp_dm_list', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'dm_list', keypath: 'dm_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_product_list', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'products_lists', keypath: 'products_lists', options: { unique: false } },
        ]
    },
    {
        store: 'acp_products', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'doctor_id', keypath: 'doctor_id', options: { unique: false } },
            { name: 'products', keypath: 'products', options: { unique: false } },
            { name: 'acp_product_list', keypath: 'acp_product_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_products_planned', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'products', keypath: 'products', options: { unique: false } },
            { name: 'acp_product_planned_list', keypath: 'acp_product_planned_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_note_list', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'note_list', keypath: 'note_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_notes', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'doctor_id', keypath: 'doctor_id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
            { name: 'acp_notes_list', keypath: 'acp_notes_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_notes_planned', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
            { name: 'acp_notes_planned_list', keypath: 'acp_notes_planned_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_notes_activity', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
            { name: 'acp_notes_activity_list', keypath: 'acp_notes_activity_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_notes_activity_planned', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'acp_date', keypath: 'acp_date', options: { unique: false } },
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'notes', keypath: 'notes', options: { unique: false } },
            { name: 'acp_notes_activity_planned_list', keypath: 'acp_notes_activity_planned_list', options: { unique: false } },
        ]
    },
    
    {
        store: 'acp_incidental_doctor_start', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_doctor_start_list', keypath: 'incidental_doctor_start_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_doctor_start_planned', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_doctor_start_planned', keypath: 'incidental_doctor_start_planned', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity_start', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_activity_start_list', keypath: 'incidental_activity_start_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_incidental_activity_start_planned', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'incidental_activity_start_planned_list', keypath: 'incidental_activity_start_planned_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_material', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_material_list', keypath: 'acp_material_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_resched_reason', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_resched_reason_list', keypath: 'acp_resched_reason_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_offline_send', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_offline_send_list', keypath: 'acp_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activity_offline_send', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_activity_offline_send_list', keypath: 'acp_activity_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_resched_offline_send', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_resched_offline_send_list', keypath: 'acp_resched_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_declared_missed_offline_send', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_declared_missed_offline_send_list', keypath: 'acp_declared_missed_offline_send_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activity_details', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_activity', keypath: 'acp_activity', options: { unique: false } },
        ]
    },
    {
        store: 'acp_reliever_holder', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reliever_holder', keypath: 'reliever_holder', options: { unique: false } },
        ]
    },
    {
        store: 'acp_joint_call_holder', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'joint_call_holder', keypath: 'joint_call_holder', options: { unique: false } },
        ]
    },
    {
        store: 'acp_detailing_aid_holder', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'detailing_aid_holder', keypath: 'detailing_aid_holder', options: { unique: false } },
        ]
    },

    {
        store: 'acp_missed_call_values', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'missed_call_values', keypath: 'missed_call_values', options: { unique: false } },
        ]
    },
    {
        store: 'acp_call_info', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_call_info_list', keypath: 'acp_call_info_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_da_product_mapping', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_da_product_mapping_list', keypath: 'acp_da_product_mapping_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_da_list_specialization', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_da_list_specialization_list', keypath: 'acp_da_list_specialization_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_detailing_aid', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_detailing_aid_list', keypath: 'acp_detailing_aid_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_activity_info', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_activity_info_list', keypath: 'acp_activity_info_list', options: { unique: false } },
        ]
    },
    {
        store: 'acp_dm_sign_offline_send', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'acp_dm_sign_offline_send_list', keypath: 'acp_dm_sign_offline_send_list', options: { unique: false } },
        ]
    },




    {
        store: 'form_list', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'forms', keypath: 'forms', options: { unique: false } },
        ]
    },
    {
        store: 'form_reason_list', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reason', keypath: 'reason', options: { unique: false } },
        ]
    },
    {
        store: 'form_product_list', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'product', keypath: 'product', options: { unique: false } },
        ]
    },



    {
        store: 'ngsw_version',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
            { name: 'version', keypath: 'version', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_rate_past', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'rate_past', keypath: 'rate_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_reach_past', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reach_past', keypath: 'reach_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_concentration_past', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'concentration_past', keypath: 'concentration_past', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_cycle', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle', keypath: 'cycle', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_average', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'average', keypath: 'average', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_summary_headers', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'summary_headers', keypath: 'summary_headers', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_rate', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'rate', keypath: 'rate', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_dates', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'dates', keypath: 'dates', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_activities', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activities', keypath: 'activities', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_reach', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'reach', keypath: 'reach', options: { unique: false } },
        ]
    },
    {
        store: 'dashboard_concentration', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'concentration', keypath: 'concentration', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_active', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_active', keypath: 'time_in_active', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_date', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_date', keypath: 'time_in_date', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_image', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_image', keypath: 'time_in_image', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_remarks', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_remarks', keypath: 'time_in_remarks', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_in_signature', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_signature', keypath: 'time_in_signature', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_new_attendance', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'new_attendance', keypath: 'new_attendance', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_active', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_active', keypath: 'time_in_active', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_date', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_date', keypath: 'time_in_date', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_image', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_image', keypath: 'time_in_image', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_remarks', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_remarks', keypath: 'time_in_remarks', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_time_out_signature', storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'time_in_signature', keypath: 'time_in_signature', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_end_start_time',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'end_start_time', keypath: 'end_start_time', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_month_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'month_list', keypath: 'month_list', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_batch_code',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'batch_code', keypath: 'batch_code', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_institution_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'institution_list', keypath: 'institution_list', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_selected_institution',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'selected_institution', keypath: 'selected_institution', options: { unique: false } },
        ]
    },
    {
        store: 'attendance_offline_upload',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'offline_upload', keypath: 'offline_upload', options: { unique: false } },
        ]
    },
    {
        store: 'user_location',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'location', keypath: 'location', options: { unique: false } },
        ]
    },
    {
        store: 'detailing_aid_file_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'file_list', keypath: 'file_list', options: { unique: false } },
        ]
    },
    {
        store: 'detailing_aid_downloaded_file',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'downloaded_file', keypath: 'downloaded_file', options: { unique: false } },
        ]
    },
    {
        store: 'dev_plan_filters',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'dev_plan_filters', keypath: 'dev_plan_filters', options: { unique: false } }
        ]
    },
    {
        store: 'dev_plan_headers',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'headers', keypath: 'headers', options: { unique: false } }
        ]
    },
    {
        store: 'dev_plan_details',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'details', keypath: 'details', options: { unique: false } }
        ]
    },
    {
        store: 'dev_plan_deleted_details',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'deleted_detail', keypath: 'deleted_detail', options: { unique: false } }
        ]
    },
    {
        store: 'material_monitoring_cycle',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle', keypath: 'cycle', options: { unique: false } }
        ]
    },
    {
        store: 'material_monitoring_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } }
        ]
    },
    {
        store: 'material_monitoring_slp_values_cycle_1',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'slp_values', keypath: 'slp_values', options: { unique: false } },
        ]
    },
    {
        store: 'material_monitoring_slp_values_cycle_2',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'slp_values', keypath: 'slp_values', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_doctor_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctor_list', keypath: 'doctor_list', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_activity_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'activity_list', keypath: 'activity_list', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_doctor_info',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctor_info', keypath: 'doctor_info', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_doctor_filters',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'doctor_filters', keypath: 'doctor_filters', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_boolean',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_boolean', keypath: 'cycle_boolean', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_one',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_one', keypath: 'cycle_one', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_two',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_two', keypath: 'cycle_two', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_active_cycle',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'active_cycle', keypath: 'active_cycle', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_numbers',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_numbers', keypath: 'cycle_numbers', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_cycle_dates',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_dates', keypath: 'cycle_dates', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'plotted_doctors', keypath: 'plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_next_cycle_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'next_cycle_plotted_doctors', keypath: 'next_cycle_plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'plotted_activities', keypath: 'plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_next_cycle_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'next_cycle_plotted_activities', keypath: 'next_cycle_plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_header_id',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'header_id', keypath: 'header_id', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_show_disapprove',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'show_disapprove', keypath: 'show_disapprove', options: { unique: false } },
        ]
    },
    {
        store: 'htmlFiles',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'zipId', keypath: 'zipId', options: { unique: false } },
            { name: 'path', keypath: 'path', options: { unique: false } },
            { name: 'content', keypath: 'content', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_submitted_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_plotted_doctors', keypath: 'submitted_plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_submitted_next_cycle_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_next_cycle_plotted_doctors', keypath: 'submitted_next_cycle_plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_submitted_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_plotted_activities', keypath: 'submitted_plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_submitted_next_cycle_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_next_cycle_plotted_activities', keypath: 'submitted_next_cycle_plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_saved_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_plotted_doctors', keypath: 'saved_plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_saved_next_cycle_plotted_doctors',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_next_cycle_plotted_doctors', keypath: 'saved_next_cycle_plotted_doctors', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_saved_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_plotted_activities', keypath: 'saved_plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'mcp_saved_next_cycle_plotted_activities',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_next_cycle_plotted_activities', keypath: 'saved_next_cycle_plotted_activities', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_pmr_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'pmr_list', keypath: 'pmr_list', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_service_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'service_list', keypath: 'service_list', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_pmr_info',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'pmr_info', keypath: 'pmr_info', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_cycle_boolean',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_boolean', keypath: 'cycle_boolean', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_cycle_one',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_one', keypath: 'cycle_one', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_cycle_two',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_two', keypath: 'cycle_two', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_active_cycle',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'active_cycle', keypath: 'active_cycle', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_cycle_numbers',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_numbers', keypath: 'cycle_numbers', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_cycle_dates',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'cycle_dates', keypath: 'cycle_dates', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_plotted_pmrs',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'plotted_pmrs', keypath: 'plotted_pmrs', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_next_cycle_plotted_pmrs',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'next_cycle_plotted_pmrs', keypath: 'next_cycle_plotted_pmrs', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_plotted_service_call',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'plotted_service_call', keypath: 'plotted_service_call', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_next_cycle_plotted_service_call',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'next_cycle_plotted_service_call', keypath: 'next_cycle_plotted_service_call', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_saved_plotted_pmrs',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_plotted_pmrs', keypath: 'saved_plotted_pmrs', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_saved_next_cycle_plotted_pmrs',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_next_cycle_plotted_pmrs', keypath: 'saved_next_cycle_plotted_pmrs', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_saved_plotted_service_call',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_plotted_service_call', keypath: 'saved_plotted_service_call', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_saved_next_cycle_plotted_service_call',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'saved_next_cycle_plotted_service_call', keypath: 'saved_next_cycle_plotted_service_call', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_submitted_plotted_pmrs',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_plotted_pmrs', keypath: 'submitted_plotted_pmrs', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_submitted_next_cycle_plotted_pmrs',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_next_cycle_plotted_pmrs', keypath: 'submitted_next_cycle_plotted_pmrs', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_submitted_plotted_service_call',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_plotted_service_call', keypath: 'submitted_plotted_service_call', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_submitted_next_cycle_plotted_service_call',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'submitted_next_cycle_plotted_service_call', keypath: 'submitted_next_cycle_plotted_service_call', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_header_id',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'header_id', keypath: 'header_id', options: { unique: false } },
        ]
    },
    {
        store: 'dm_mcp_to_be_save_mcp',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'to_be_save_mcp', keypath: 'to_be_save_mcp', options: { unique: false } },
        ]
    },


    {

        store: 'quick_coverage_delete_timestamps',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'delete_timestamp', keypath: 'delete_timestamp', options: { unique: false } }
        ]
    },
    {
        store: 'quick_coverage_settings',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'settings', keypath: 'settings', options: { unique: false } }
        ]
    },
    {
        store: 'quick_sign',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'quick_sign', keypath: 'quick_sign', options: { unique: false } }
        ]
    },
    {
        store: 'quick_sign_images',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'file', keypath: 'file', options: { unique: false } },
            { name: 'sign_id', keypath: 'sign_id', options: { unique: false } }
        ]
    },
    {
        store: 'quick_photo',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'quick_photo', keypath: 'quick_photo', options: { unique: false } }
        ]
    },
    {
        store: 'quick_photo_images',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'file', keypath: 'file', options: { unique: false } },
            { name: 'photo_id', keypath: 'photo_id', options: { unique: false } }
        ]
    },
    {
        store: 'quick_coverage_pending_upload',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'pending_upload', keypath: 'pending_upload', options: { unique: false } }
        ]
    },
    {
        store: 'quick_coverage_pending_upload_images',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'pending_upload_images', keypath: 'pending_upload_images', options: { unique: false } }
        ]
    },
    {
        store: 'quick_coverage_match_log',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'match_log', keypath: 'match_log', options: { unique: false } }
        ]
    },
    {
        store: 'quick_coverage_location',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'location', keypath: 'location', options: { unique: false } }
        ]

    },
    {

        store: 'so_reasons_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'so_reasons', keypath: 'so_reasons', options: { unique: false } },
        ]
    },
    {
        store: 'so_institutions_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'so_institutions', keypath: 'so_institutions', options: { unique: false } },
        ]
    },
    {
        store: 'so_headers_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'user_id', keypath: 'user_id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_headers', keypath: 'so_headers', options: { unique: false } },
        ]
    },
    {
        store: 'so_blocking_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_blocking', keypath: 'so_blocking', options: { unique: false } }
        ]
    }
        ,
    {
        store: 'so_details_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_details', keypath: 'so_details', options: { unique: false } },
        ]
    },
    {
        store: 'so_existing_data_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_reference', keypath: 'so_reference', options: { unique: false } },
            { name: 'so_existing_data', keypath: 'so_existing_data', options: { unique: false } },
        ]
    },
    {
        store: 'so_items_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'payment_terms_id', keypath: 'payment_terms_id', options: { unique: false } },
            { name: 'so_items', keypath: 'so_items', options: { unique: false } },
        ]
    },
    {
        store: 'so_payment_terms_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_payment_terms', keypath: 'so_payment_terms', options: { unique: false } },
        ]
    },
    {
        store: 'so_txn_for_sync_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_reference', keypath: 'so_reference', options: { unique: false } },
            { name: 'so_txn_for_sync', keypath: 'so_txn_for_sync', options: { unique: false } },
        ]
    },
    {
        store: 'so_image_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_reference', keypath: 'so_reference', options: { unique: false } },
            { name: 'so_image', keypath: 'so_image', options: { unique: false } },
        ]
    },
    {
        store: 'so_sign_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_reference', keypath: 'so_reference', options: { unique: false } },
            { name: 'so_sign', keypath: 'so_sign', options: { unique: false } },
        ]
    },
    {
        store: 'so_delete_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: false } },
            { name: 'customer_id', keypath: 'customer_id', options: { unique: false } },
            { name: 'so_id', keypath: 'so_id', options: { unique: false } },
            { name: 'so_reference', keypath: 'so_reference', options: { unique: false } },
        ]
    },
    {
        store: 'so_settings_list',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'so_settings', keypath: 'so_settings', options: { unique: false } },
        ]
    },
    {
        store: 'tutorial_faqs',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
            { name: 'id', keypath: 'id', options: { unique: true } },
            { name: 'files', keypath: 'files', options: { unique: false } },
        ]
    },
    ]
}