<div fxLayout="row" class="page-footer">
        <div fxFlex fxFlexAlign="center"> 
                <mat-label class="mat-body-2">
                        © Electronic Science. All Rights Reserved 2025
                        <br>
                        <!-- <a href="/terms-and-privacy/terms-of-service" target="_self">Terms of Service</a> |
                        <a href="/terms-and-privacy/privacy-policy" target="_self">Privacy Policy</a> |
                        <a href="https://electronicscience.com/dpoqr.html" target="_blank">National Privacy Commission Seal</a> -->
                </mat-label>
        </div>
</div>