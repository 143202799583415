import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import axios from 'axios';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { DialogService } from 'src/app/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OfflineStorageService } from 'src/app/pages/quick-sign/offline-storage.service';
import { AcpIndexDBService } from 'src/app/services/indexed-db/acp-index-db.service';

@Component({
  selector: 'esc-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss']
})
export class SignatureModalComponent implements OnInit {
  @ViewChild('modalSignature') modalSignature!: ElementRef<HTMLCanvasElement>;

  private canvas!: HTMLCanvasElement;
  private ctx!: CanvasRenderingContext2D | null;
  private drawing = false;
  public field: any = {};
  public canvasEmpty = true;
  public validated = false;
  public loading = {
    save: false
  };

  private location: { latitude: number; longitude: number; address: string } = {
    latitude: 0, longitude: 0, address: ''
  };

  private locationSubject = new BehaviorSubject<{ latitude: number; longitude: number; address: string }>
    ({ latitude: 0, longitude: 0, address: '' });
  location$ = this.locationSubject.asObservable();

  public userHolder = {
    id: 0,
    first_name: "",
    middle_name: "",
    last_name: "",
    username: "",
    email_address: "",
    code: "",
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private matDialogRef: MatDialogRef<SignatureModalComponent>,
    private indexDBService: AcpIndexDBService,
    private offlineStorage: OfflineStorageService
  ) { }

  async ngOnInit() {
    const userData = this.indexDBService.getFromLocalStorage("app_user");
    if (userData && Object.keys(userData).length > 0) {
      this.userHolder = userData;
    }

    this.field = this.data
    await this.getPosition();
  }

  ngAfterViewInit() {
    this.canvas = this.modalSignature.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    this.resizeCanvas();
    window.addEventListener('resize', this.resizeCanvas.bind(this));
  }

  ngOnDestroy(): void {
  }

  // LOCATON
  private async getPosition() {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser');
      return;
    }

    if (navigator.onLine) {
      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          });
        });

        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        try {
          const address = await this.getAddress(latitude, longitude);
          const locationData = { latitude, longitude, address };
          this.location = locationData;
          this.locationSubject.next(locationData);
          console.log('Location updated:', this.location); // Debug log

          // Save location to IndexedDB
          const userLocation = { location: locationData };
          await this.offlineStorage.saveUserLocation(userLocation, this.userHolder.id);
        } catch (error) {
          console.error('Error getting address:', error);
        }
      } catch (error) {
        console.error('Error getting position:', error);
      }
    } else {
      const savedLocation = await this.offlineStorage.getUserLocation(this.userHolder.id);
      if (savedLocation.length > 0) {
        this.location = savedLocation[0].location;
        this.locationSubject.next(savedLocation[0].location);
        console.log('Retrieved saved location:', savedLocation[0].location); // Debug log
      } else {
        console.error('No saved location found.');
      }
    }
  }

  private async getAddress(latitude: number, longitude: number): Promise<string> {
    try {
      const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      const response = await axios.get(apiUrl, {

      });

      if (response.data && response.data.display_name) {
        console.log('Address received:', response.data.display_name); // Debug log
        return response.data.display_name;
      } else {
        throw new Error('No address data in response');
      }
    } catch (error) {
      console.error('Reverse geocoding failed:', error);
      throw error;
    }
  }

  resizeCanvas() {
    if (this.canvas) {
      const parentWidth = Math.min(window.innerWidth * 0.8);
      const parentHeight = window.innerHeight * 0.6;

      this.canvas.width = parentWidth;
      this.canvas.height = parentHeight;
    }
  }

  startDrawing(event: MouseEvent | TouchEvent) {
    if (event instanceof TouchEvent) {
      event.preventDefault(); // prevent scrolling while drawing
    }
    this.drawing = true;
    const { x, y } = this.getCanvasCoordinates(event);
    this.ctx?.beginPath();
    this.ctx?.moveTo(x, y);
  }

  draw(event: MouseEvent | TouchEvent) {
    if (event instanceof TouchEvent) {
      event.preventDefault(); // prevent scrolling while drawing
    }
    if (!this.drawing || !this.ctx) return;
    const { x, y } = this.getCanvasCoordinates(event);
    this.ctx.lineTo(x, y);
    this.ctx.strokeStyle = '#000';
    this.ctx.lineWidth = 2;
    this.ctx.lineCap = 'round';
    this.ctx.stroke();
    this.canvasEmpty = false;
  }

  stopDrawing() {
    if (this.drawing) {
      this.drawing = false;
      this.ctx?.closePath();
    }
  }

  private executeClear(): void {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.canvasEmpty = true;
      this.validated = false;
      this.modalSignature.nativeElement.style.pointerEvents = 'auto';
      this.modalSignature.nativeElement.style.opacity = '1';
    }
  }

  clearCanvas() {
    this.dialogService.confirm({
      title: 'Clear Signature',
      message: 'Are you sure you want to clear the current signature?'
    }).subscribe(result => {
      if (result) {
        this.executeClear();
      }
    });
  }

  private getCanvasCoordinates(event: MouseEvent | TouchEvent): { x: number; y: number } {
    const rect = this.canvas.getBoundingClientRect();
    const clientX = (event as TouchEvent).touches?.[0]?.clientX || (event as MouseEvent).clientX;
    const clientY = (event as TouchEvent).touches?.[0]?.clientY || (event as MouseEvent).clientY;

    // Account for canvas scaling and CSS pixels
    const scaleX = this.canvas.width / rect.width;
    const scaleY = this.canvas.height / rect.height;

    return {
      x: (clientX - rect.left) * scaleX,
      y: (clientY - rect.top) * scaleY
    };
  }

  async handleSignClick() {

    this.loading.save = true;

    this.modalSignature.nativeElement.style.pointerEvents = 'none';
    this.modalSignature.nativeElement.style.opacity = '0.6';

    const bounds = this.getSignatureBounds();
    if (!bounds) {
      console.error('No signature found');
      this.loading.save = false;
      return;
    }

    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    if (!tempCtx) return;

    const watermarkConfig = {
      font: '10px Arial',
      fillStyle: 'rgba(0, 0, 0, 0.7)',
      padding: 10,
      lineHeight: 16
    };

    tempCtx.font = watermarkConfig.font;
    const requiredWidth = this.calculateRequiredWidth(tempCtx, this.location);
    const finalWidth = Math.max(bounds.width, requiredWidth);
    const watermarkHeight = (3 * watermarkConfig.lineHeight) + (watermarkConfig.padding * 2);

    tempCanvas.width = finalWidth;
    tempCanvas.height = bounds.height + watermarkHeight;
    tempCtx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);

    if (tempCtx) {
      tempCtx.drawImage(
        this.canvas,
        bounds.x, bounds.y, bounds.width, bounds.height,
        (finalWidth - bounds.width) / 2, 0, bounds.width, bounds.height
      );
      
      // if field is 15 (Signature w/ GPS), add watermark
      if (this.field.itemTypeId == 15) {
        this.addWatermarkToCanvas(tempCtx, tempCanvas, this.location, watermarkConfig, bounds.height);
      }
    }

    // this.addWatermarkToSignature();

    const signImage = tempCanvas.toDataURL('image/png');
    this.validated = true;
    const timestamp = moment().format('YYYY-MM-DDTHHmmss');
    const context = 'forms';

    const fileName = `${context}-signature-${timestamp}.png`;

    if (this.validated) {
      this.matDialogRef.close({ 
        imageData: signImage,
        fileName: fileName
      });
    }

    this.executeClear();
  }

  private calculateRequiredWidth(context: CanvasRenderingContext2D, location: any): number {
    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    const lines = [
      `Location: ${location.address || 'Unknown'}`,
      `Coordinates: ${location.latitude}, ${location.longitude}`,
      `Timestamp: ${timestamp}`
    ];

    let maxWidth = 0;
    lines.forEach(line => {
      const metrics = context.measureText(line);
      maxWidth = Math.max(maxWidth, metrics.width);
    });
    return maxWidth + 40;
  }

  private getSignatureBounds() {
    if (!this.ctx) return null;

    const pixels = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
    const data = pixels.data;
    let minX = this.canvas.width;
    let minY = this.canvas.height;
    let maxX = 0;
    let maxY = 0;

    for (let y = 0; y < this.canvas.height; y++) {
      for (let x = 0; x < this.canvas.width; x++) {
        const alpha = data[((y * this.canvas.width + x) * 4) + 3];
        if (alpha > 0) {
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }

    const padding = 20;
    minX = Math.max(0, minX - padding);
    minY = Math.max(0, minY - padding);
    maxX = Math.min(this.canvas.width, maxX + padding);
    maxY = Math.min(this.canvas.height, maxY + padding);

    if (minX > maxX || minY > maxY) {
      return null;
    }

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY
    };
  }

  private addWatermarkToCanvas(
    context: CanvasRenderingContext2D | null,
    canvas: HTMLCanvasElement,
    location: any,
    config: {
      font: string;
      fillStyle: string;
      padding: number;
      lineHeight: number;
    },
    startY: number = 0
  ): void {
    // Early return if watermark is disabled
    // if (!this.enableWatermark) {
    //   return;
    // }
    if (!context) return;

    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');

    context.font = config.font;
    context.fillStyle = config.fillStyle;

    const lines = [
      `Location: ${location.address || 'Unknown'}`,
      `Coordinates: ${location.latitude}, ${location.longitude}`,
      `Timestamp: ${timestamp}`
    ];

    // Draw each line without wrapping
    lines.forEach((line, index) => {
      const y = startY + config.padding + (config.lineHeight * (index + 1));
      context.fillText(line, config.padding, y);
    });
  }

  dialogClose() {
    this.matDialogRef.close();
  }
}
